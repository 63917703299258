<template>
  <v-container fluid>
    <smartx-oms-trade-desk-wc
      :auth-token="authToken"
      :environment="env"
      :parent-environment="clientEnv"
    />
  </v-container>
</template>

<script>

export default {
  data () {
    return {
      authToken: '',
      // can pass this as parentEnv prop but not needed -- empty prop should be treated the same
      clientEnv: '1abc4124fa0e'
    }
  },
  created () {
    this.authToken = localStorage.getItem('token')
  },
  computed: {
    env () {
      return this.$store.getters.appEnv.env_name
    }
  }
}
</script>
