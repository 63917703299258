import Vue from 'vue'
import VueRouter from 'vue-router'

import NotFound from '@/views/NotFound.vue'
import Unauthorized from '@/views/Unauthorized.vue'

import { TradeGenerationContainer } from '@/components/trade-generation'
import { TradeDeskContainer } from '@/components/trade-desk'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/trade-generation',
    name: 'Trade Generation',
    component: TradeGenerationContainer,
    meta: { requiresAuth: true, role: 'Trader' }
  },
  {
    path: '/trade-desk',
    name: 'Trade Desk',
    component: TradeDeskContainer,
    meta: { requiresAuth: true, role: 'Trader' }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
    meta: { requiresAuth: false, role: 'Trader' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found',
    component: NotFound,
    meta: { requiresAuth: false, role: 'Trader' }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
