import Vue from 'vue'
import lodash from 'lodash'
import VueLodash from 'vue-lodash'
import Notifications from 'vue-notification'

import App from './App.vue'
import router from './router'
import store from './store'

import { AuthService, vuetify } from '@/plugins'

import LogRocket from 'logrocket'
LogRocket.init('hqtqlf/smartx-enterprise-portal', {
  network: { isEnabled: false }
})

Vue.config.productionTip = false
Vue.use(AuthService, { router })

Vue.use(VueLodash, { lodash })
Vue.use(Notifications)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
