<template>
  <v-toolbar color="primary" dark flat>
    <v-btn v-for="(item, i) in menuItems" :key="i" :to="item.href" style="color: white" text>
      {{ item.text }}
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  data () {
    return {
      menuItems: [
        {
          text: 'Trade Generation',
          href: '/trade-generation'
        },
        {
          text: 'Trade Desk',
          href: 'trade-desk'
        }
      ]
    }
  }
}
</script>
